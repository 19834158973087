<template>
  <div class="our-talents">
    <ul>
      <template v-for="talent of talents">
        <li>
          <hr>
        </li>
        <li>
          <img :src="getTalentImage(talent)" width="150"/>
          <a :href="`/talent/${talent.screenName}`">{{ talent.displayName }} // {{ getTalentJob(talent) }} </a>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
  name: 'talents',
  metaInfo: {
    title: "Liste de nos talents",
    titleTemplate: '%s - DRiMSCREATiVE ',
    meta: [
      {
        name: "description",
        content: "Agents de Freelances, Agent de Talents"
      }
    ]
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(['talents']),
  },
  methods: {
    getTalentImage (talent) {
      return `http://static.drimscreative.com${talent.avatar2 || talent.avatar}`
    },
    getTalentJob (talent) {
      switch (talent.profilType) {
        case 'CREA':
          return 'Créative'
        case 'DEV':
          return 'Développeur'
        case 'GES':
          return 'Chef de Projet'
      }
    },
  },
}
</script>
<style lang="scss">
.our-talents {
  img {
    margin-right: 20px;
  }
}
</style>
